<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : ''}`" ref="index">
    <div class="shrining"></div>
    <pop-login
      :show="popLoginShow"
      :projectcode="project_code"
      @close="popLoginClose"
    ></pop-login>
    <div class="page-one" ref="pageOne">
      <pop-top
        @open="popLoginOpen"
        @logout="popLogout"
        :weappPathFinished="weappPathFinished"
        :weappPath="weappPath"
      ></pop-top>
      <span class="h1">METAVERSE</span>
      <span class="h2">新⼀代元宇宙活动平台</span>
      <div class="h3">
        <p>
          为⽤户打造具有仪式感、沉浸感的虚拟空间，提供身临其境的、⾼互动性的活动体验。
        </p>
      </div>
      <div class="page-action">
        <div
          class="action-item active cursor-pointer"
          @click.stop.prevent="locateGame"
        >
          <span>加入活动</span>
        </div>
        <div
          class="action-item cursor-pointer"
          v-if="!userInfo && $utils.checkWxOrMini(1) && weappPathFinished"
        >
          <WxOpenLaunchWeapp :path="weappPath">
            <div :style="weappStyle.div">
              <span :style="weappStyle.span">创建活动</span>
            </div>
          </WxOpenLaunchWeapp>
        </div>
        <div
          class="action-item cursor-pointer"
          @click.stop.prevent="createMyActivity"
          v-else
        >
          <span>创建活动</span>
        </div>
      </div>
    </div>
    <div class="page-two">
      <span class="h1">ACTIVITY FUNCTION</span>
      <div class="h2">
        <div class="icon-planet"></div>
        <span>活动功能</span>
      </div>
      <div class="intro">
        <div
          :class="`intro-item bg-${i.class}`"
          v-for="(i, k) in introList"
          :key="k"
        >
          <span>{{ i.h1 }}</span>
          <span>{{ i.h2 }}</span>
          <p>{{ i.h3 }}</p>
        </div>
      </div>

      <span class="h1" ref="center">CHANNEL CENTER</span>
      <div class="h2">
        <div class="icon-planet"></div>
        <span>频道中心</span>
      </div>
      <channel-swiper class="channerSwiper"></channel-swiper>
      <span class="h1" ref="center">ACTIVITY CENTER</span>
      <div class="h2">
        <div class="icon-planet"></div>
        <span>活动中心</span>
      </div>
      <div class="list">
        <div
          class="list-item cursor-pointer"
          v-for="(i, k) in activityList"
          :key="k"
          @click.stop.prevent="goDetail(i)"
        >
          <img :src="i.cover" />
          <div class="down">
            <span class="label">ACTIVITYD</span>
            <p class="title">{{ i.title }}</p>
            <div class="time">
              <div class="icon-time"></div>
              <span>{{ i.start_time.substring(0, 16) }}</span>
              <span>--</span>
              <span>{{ i.end_time.substring(0, 16) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="list-arrow" v-if="activityList.length > 0">
        <div
          class="arrow-left cursor-pointer"
          @click.stop.prevent="arrowClick(0)"
        ></div>
        <div
          class="arrow-right cursor-pointer"
          @click.stop.prevent="arrowClick(1)"
        ></div>
      </div>
    </div>
    <div class="page-three">
      <div class="left">
        <span class="h1">METAVERSE</span>
        <div class="h2">
          <div class="icon-phone"></div>
          <span>400-889-1113</span>
        </div>
        <div class="h3">
          <div class="icon-company"></div>
          <span>⼴州⼩包⼦数据科技有限公司</span>
        </div>
        <div class="h4">
          <div class="icon-location"></div>
          <span>⼴州市⻩埔区科学城科汇四街5号301-1房</span>
        </div>
      </div>
      <div class="right">
        <span class="h1">CONTACT US</span>
        <span class="h2">联系我们</span>
        <div class="qrcode"></div>
      </div>
      <div class="bottom">
        <span>Homepage</span>
        <span>Activity Function</span>
        <span>Activity Center</span>
        <span>Contact Us</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert } from "@/router/index";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopLogin from "@/components/PopLogin.vue";
import PopTop from "@/components/PopTop.vue";
import channelSwiper from "@/views/channel/channelSwiper.vue";
export default {
  components: { PopLogin, PopTop, WxOpenLaunchWeapp, channelSwiper },
  data() {
    return {
      href: location.href,
      popLoginShow: false,
      project_code: "",
      introList: [
        {
          class: "purple",
          h1: "模拟实景",
          h2: "SIMULATED LIVE SCENE",
          h3: "依托虚拟现实技术搭建活动3D场景，营造活动仪式感、趣味性，吸引更多⽤户参加",
        },
        {
          class: "green",
          h1: "虚拟⻆⾊",
          h2: "VIRTUAL",
          h3: "⽤户各⾃控制⼀个虚拟⻆⾊进⼊场地参加活动，可以⾃由浏览、发⾔，⾼度模拟线下活动的情景",
        },
        {
          class: "orange",
          h1: "投屏放映",
          h2: "SCREEN PROJECTION",
          h3: "主办⽅可在模拟实景屏幕内放映⾳视频、图⽚、幻灯⽚等，⽀持屏幕共享，⾼度模拟会场⼤屏幕功能",
        },
        {
          class: "blue",
          h1: "观众互动",
          h2: "AUDIENCE INTERACTION",
          h3: "观众可⾃由⾛动/就坐，增强⽤户参与感；打招呼、⿎掌、跑步等动作，助⼒活跃氛围",
        },
      ],
      activityList: [],
      activityListCurrentIndex: 0,
      activityListOrigin: [],
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "0",
          transition: "all 0.3s ease-in-out",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "16px",
          fontFamily: "Source Han Sans CN-Normal, Source Han Sans CN",
          fontWeight: "400",
          color: "#ffffff",
        },
      },
      weappPathFinished: false,
      weappLoginFinished: false,
      qrcode_scene: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    weappPath() {
      return `subpackage_login_cy/index/index?scene=${
        process.env.NODE_ENV == "development" ? "1_" : "_"
      }1_${this.qrcode_scene}_1${
        sessionStorage.getItem("share_id")
          ? "_" + sessionStorage.getItem("share_id")
          : ""
      }${this.$storage.getLogoutExist() ? "&dc_skip=1" : ""}`;
    },
  },
  created() {
    this.qrcode_scene = Date.now();
    this.getInfo();
    document.title = process.env.VUE_APP_DEFAULT_TITLE;
    // window.addEventListener("resize", this.getInfo);
    wxShare({});
    if (
      this.userInfo &&
      sessionStorage.getItem("isWxMini") == 1 &&
      sessionStorage.getItem("activity_id") &&
      sessionStorage.getItem("activity_id") > 1 &&
      sessionStorage.getItem("backIndex") != 1
    ) {
      this.$router.push({
        name: routesConvert(routesModuleData.detail, true).name,
        query: {
          id: sessionStorage.getItem("activity_id") || 0,
          al: this.$utils.getUrlParam("al") || "",
          stay: this.$utils.getUrlParam("stay") || "",
        },
      });
    } else {
      this.$store.dispatch("saveCsQrcode", {
        notActivityId: true,
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getInfo);
  },
  mounted() {},
  methods: {
    popLogout() {
      this.weappLoginFinished = false;
      this.qrcode_scene = Date.now();
      this.internalRequest();
    },
    internalRequest() {
      // 场景：微信h5内首页有唤醒小程序扫码入口，需持续轮询检测登录。
      if (this.userInfo) {
        // 已登录
        return;
      }
      if (!this.$utils.checkWxOrMini(1)) {
        // 非微信h5端内
        return;
      }
      if (this.weappLoginFinished) {
        // 已扫码登录完成
        return;
      }
      const _this = this;
      _this.$http
        .post("/user/wxAuthNew", {
          timestamp: _this.qrcode_scene,
        })
        .then((res) => {
          if (res.code == 200 && res.data && res.data.user_info) {
            _this.$storage.setToken(res.data.user_info.token);
            _this.$store.dispatch("saveUserInfo", res.data.user_info);
            _this.$store.dispatch("saveConfig", res.data.config);
            _this.$toast.success("登录成功");
            _this.weappLoginFinished = true;
            _this.qrcode_scene = Date.now();
          } else {
            setTimeout(() => {
              _this.internalRequest();
            }, 1500);
          }
        });
    },
    createMyActivity() {
      if (!this.userInfo) {
        this.popLoginOpen();
        return;
      }
      this.$router.push({
        name: routesConvert(routesModuleData.manage, true).name,
      });
    },
    goDetail(item) {
      // if (sessionStorage.getItem("isWxMini") == 1) {
      //   parent.wx.miniProgram.reLaunch({
      //     url: `/subpackage_login_cy/game/game?id=${item.id}${
      //       process.env.NODE_ENV == "development" ? "_1" : ""
      //     }&al=1${this.userInfo ? "&skip=1" : ""}`,
      //   });
      // } else {
      this.$router.push({
        name: routesConvert(routesModuleData.detail, true).name,
        query: { id: item.id },
      });
      // }
    },
    locateGame() {
      const _this = this;
      let distance =
          this.$refs.center.offsetTop + this.$refs.pageOne.clientHeight - 100 ||
          2150,
        step = 80;
      // if (this.$utils.isMobile().mobile) {
      //   if (window.matchMedia("(orientation: portrait)").matches) {
      //     distance =
      //       window.screen.availHeight +
      //       (window.screen.availHeight <= 667 ? 820 : 750);
      //   } else {
      //     distance = 910;
      //     step = 50;
      //   }
      // }
      let timer = requestAnimationFrame(function fn() {
        if (_this.$refs.index.scrollTop < distance) {
          _this.$refs.index.scrollTop += step;
          requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
        }
      });
    },
    arrowClick(type) {
      let step = 3;
      if (
        this.$utils.isMobile().mobile &&
        window.matchMedia("(orientation: portrait)").matches
      ) {
        step = 1;
      }
      const length = this.activityListOrigin.length;
      if (type) {
        // 向右
        if (length - 1 < this.activityListCurrentIndex + step) {
          return;
        }
        this.activityListCurrentIndex = this.activityListCurrentIndex + step;
      } else {
        // 向左
        if (this.activityListCurrentIndex - step < 0) {
          return;
        }
        this.activityListCurrentIndex = this.activityListCurrentIndex - step;
      }
      this.activityList = this.activityListOrigin.slice(
        this.activityListCurrentIndex,
        this.activityListCurrentIndex + step
      );
    },
    getInfo() {
      this.$http
        .get("/activity/activity-center", {
          page: 1,
          per_page: 999,
        })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.project_code = res.data.project_code;
            this.activityListOrigin = res.data.data.data;
            const activityListOriginLength = this.activityListOrigin.length;
            this.activityList = [];
            this.activityListCurrentIndex = 0;
            if (
              this.$utils.isMobile().mobile &&
              window.matchMedia("(orientation: portrait)").matches
            ) {
              activityListOriginLength > 0 &&
                this.activityList.push(this.activityListOrigin[0]);
            } else {
              activityListOriginLength > 0 &&
                this.activityList.push(this.activityListOrigin[0]);
              activityListOriginLength > 1 &&
                this.activityList.push(this.activityListOrigin[1]);
              activityListOriginLength > 2 &&
                this.activityList.push(this.activityListOrigin[2]);
            }
            this.weappPathFinished = true;
            this.internalRequest();
          }
        });
    },
    logout() {
      this.$storage.clearToken();
      this.$store.dispatch("clearUserInfo", "");
      this.$store.dispatch("clearConfig", "");
      this.$toast.success("已退出登录");
    },
    popLoginClose() {
      this.popLoginShow = false;
    },
    popLoginOpen() {
      if (sessionStorage.getItem("isWxMini") == 1) {
        parent.wx.miniProgram.reLaunch({
          url: `/subpackage_login_cy/game/game?id=1${
            process.env.NODE_ENV == "development" ? "_1" : "_"
          }&al=1${this.$storage.getLogoutExist() ? "&dc_skip=1" : ""}`,
        });
      } else {
        this.popLoginShow = true;
      }
    },
    onCopy() {
      this.$toast.success("复制成功");
    },
    onError() {
      this.$toast.fail("复制失败");
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes shrine {
  0% {
    position: absolute;
    left: -100%;
    top: 0;
    width: 30%;
    height: 100%;
    content: "";
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: skewX(-45deg);
    transition: 0.5s ease-in-out;
  }
  100% {
    left: 300%;
    transition: 0.5s ease-in-out;
  }
}
.shrining {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;
  z-index: 99;
  animation: shrine 4s infinite linear;
}
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  .page-one {
    width: 100%;
    height: 2160px;
    background: url(#{$PublicEnv}/index/bg_1_pc_2.png);
    background-size: 100% 100%;
    background-position: center;
    transition: 1s ease-in-out;
    animation: backgroundMove 8s infinite linear;
    position: relative;
    .h1 {
      position: absolute;
      top: 720px;
      left: 380px;
      font-size: 80px;
      font-family: Orbitron-Black, Orbitron;
      font-weight: 900;
      letter-spacing: 8px;
      text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
      background: linear-gradient(
        93deg,
        #ffffff 1%,
        rgba(127, 153, 253, 0) 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .h2 {
      position: absolute;
      top: 846px;
      left: 380px;
      font-size: 104px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
    .h3 {
      position: absolute;
      top: 1022px;
      left: 380px;
      display: flex;
      flex-direction: column;
      p {
        font-size: 40px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 76px;
      }
    }
    .page-action {
      display: flex;
      position: absolute;
      left: 380px;
      top: 1432px;
      justify-content: center;
      align-items: center;
      .action-item {
        width: 360px;
        height: 120px;
        border-radius: 60px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0;
        transition: all 0.3s ease-in-out;
        box-sizing: border-box;
        position: relative;
        &:hover {
          transform: scale(1.1);
        }
        span {
          font-size: 48px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        &.active {
          border: 0;
          background: rgba(77, 18, 218, 1);
          margin-right: 48px;
        }
      }
    }
  }
  &.mobile {
    .page-one {
      animation: none;
    }
  }
  .page-two {
    width: 100%;
    // height: 4332px;
    background: url("https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1701412279533.png");
    background-size: 100% auto;
    transition: 1s ease-in-out;
    // animation: backgroundMove 8s infinite linear;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .h1 {
      margin-top: 192px;
      font-size: 96px;
      font-family: Helvetica Black-Regular, Helvetica Black;
      font-weight: 800;
      line-height: 113px;
      text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
      background: linear-gradient(
        82deg,
        #522ec8 7%,
        #22a8b7 44%,
        #2f9be3 82%,
        #0075ff 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      .icon-planet {
        width: 176px;
        height: 176px;
        background: url(#{$PublicEnv}/index/icon_planet.png);
        background-size: 100% 100%;
      }
      span {
        font-size: 60px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        letter-spacing: 18px;
      }
    }
    .channerSwiper {
      margin-top: 304px;
      margin-bottom: calc(698px - 192px);
    }
    .intro {
      margin-top: 188px;
      display: flex;
      flex-wrap: wrap;
      width: 2140px;
      margin-bottom: 190px;
      .intro-item {
        width: 994px;
        height: 580px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;
        padding-left: 120px;
        padding-top: 100px;
        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 152px;
        }
        &:hover {
          transform: scale(1.1);
        }
        span {
          &:first-child {
            font-size: 52px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
          &:nth-child(2) {
            font-size: 36px;
            font-family: OPPOSans-Medium, OPPOSans;
            font-weight: 500;
            margin-top: 30px;
          }
        }
        p {
          width: 748px;
          font-size: 32px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          line-height: 60px;
          margin-top: 46px;
        }
        &.bg-purple {
          background: url(#{$PublicEnv}/index/function_purple.png);
          background-size: 105% 105%;
          span {
            &:nth-child(2) {
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(
                83deg,
                #e0bdff 0%,
                rgba(127, 153, 253, 0.73) 93%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        &.bg-green {
          background: url(#{$PublicEnv}/index/function_green.png);
          background-size: 105% 105%;
          span {
            &:nth-child(2) {
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(85deg, #ffffff 0%, #b0f9ee 93%);
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        &.bg-orange {
          background: url(#{$PublicEnv}/index/function_orange.png);
          background-size: 105% 105%;
          span {
            &:nth-child(2) {
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(83deg, #ffffff 6%, #ffeddd 80%);
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        &.bg-blue {
          background: url(#{$PublicEnv}/index/function_blue.png);
          background-size: 105% 105%;
          span {
            &:nth-child(2) {
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(
                83deg,
                #abdbf5 4%,
                rgba(206, 237, 255, 0.45) 92%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    .list {
      margin-top: 214px;
      display: flex;
      justify-content: center;
      align-items: center;
      .list-item {
        width: 882px;
        height: 910px;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 2px rgba(172, 174, 197, 59%);
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin-right: 74px;
        }
        img {
          flex-shrink: 0;
          width: 100%;
          height: 522px;
        }
        .down {
          background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 1),
            rgba(234, 247, 255, 1)
          );
          flex-shrink: 0;
          width: 100%;
          height: 388px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          box-sizing: border-box;
          padding: 48px 80px;
          .label {
            font-size: 48px;
            font-family: Helvetica Black-Regular, Helvetica Black;
            font-weight: 400;
            line-height: 56px;
            text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
            background: linear-gradient(
              270deg,
              #4e54ff 13%,
              #21b4c5 51%,
              #1285d1 91%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .title {
            margin-top: 32px;
            font-size: 44px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
          }
          .time {
            width: 100%;
            margin-top: 28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon-time {
              width: 48px;
              height: 48px;
              background: url(#{$PublicEnv}/index/icon_time.png);
              background-size: 105% 105%;
              margin-right: 20px;
              flex-shrink: 0;
            }
            span {
              font-size: 34px;
              font-family: DIN-Regular, DIN;
              font-weight: 400;
              color: #666666;
              &:nth-child(2) {
                margin: 0 6px;
              }
            }
          }
        }
      }
    }
    .list-arrow {
      margin-top: 226px;
      margin-bottom: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow-left {
        width: 88px;
        height: 88px;
        background: url(#{$PublicEnv}/index/arrow_left.png);
        background-size: 100% 100%;
        margin-right: 160px;
      }
      .arrow-right {
        width: 88px;
        height: 88px;
        background: url(#{$PublicEnv}/index/arrow_right.png);
        background-size: 100% 100%;
      }
    }
  }
  .page-three {
    width: 100%;
    height: 1264px;
    background: url(#{$PublicEnv}/index/bg_3_pc.png);
    background-size: 100% 100%;
    position: relative;
    .left {
      position: absolute;
      top: 478px;
      left: 532px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .h1 {
        font-size: 80px;
        font-family: Orbitron-Black, Orbitron;
        font-weight: 900;
        letter-spacing: 8px;
        text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
        background: linear-gradient(
          93deg,
          #ffffff 1%,
          rgba(127, 153, 253, 0) 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 16px;
      }
      .h2,
      .h3,
      .h4 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        .icon-phone {
          width: 40px;
          height: 40px;
          background: url(#{$PublicEnv}/index/icon_phone.png);
          background-size: 100% 100%;
        }
        .icon-company {
          width: 40px;
          height: 40px;
          background: url(#{$PublicEnv}/index/icon_company.png);
          background-size: 100% 100%;
        }
        .icon-location {
          width: 40px;
          height: 40px;
          background: url(#{$PublicEnv}/index/icon_location.png);
          background-size: 100% 100%;
        }
        span {
          margin-left: 34px;
          font-size: 36px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .right {
      position: absolute;
      top: 306px;
      right: 454px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .h1 {
        font-size: 96px;
        font-family: Helvetica Black-Regular, Helvetica Black;
        font-weight: 400;
        text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
        background: linear-gradient(
          82deg,
          #7b58ee 4%,
          #21b4c5 37%,
          #1285d1 81%,
          #106edc 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .h2 {
        font-size: 56px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }
      .qrcode {
        margin-top: 66px;
        width: 272px;
        height: 272px;
        background: url(#{$PublicEnv}/index/qrcode.png);
        background-size: 100% 100%;
      }
    }
    .bottom {
      position: absolute;
      right: 0;
      bottom: 132px;
      left: 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 396px;
      span {
        margin-right: 376px;
        font-size: 36px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: #ffffff;
        opacity: 0.7;
      }
      &::before {
        position: absolute;
        top: -48px;
        left: 0;
        right: 0;
        content: "";
        height: 8px;
        background: url(#{$PublicEnv}/index/line.png);
        background-size: 100% 100%;
      }
    }
  }
}
@media (orientation: portrait) {
  .index {
    .page-one {
      width: 100%;
      height: 6840px;
      background: url(#{$PublicEnv}/index/bg_1_mobile_2.png);
      background-size: 100% 100%;
      animation: none;
      .h1 {
        display: none;
      }
      .h2 {
        top: 4081px;
        left: 323px;
        font-size: 246px;
        text-shadow: 0px 20px 51px rgba(0, 0, 0, 0.302);
      }
      .h3 {
        top: 4572px;
        left: 323px;
        right: 323px;
        p {
          font-size: 143px;
          line-height: 246px;
          text-shadow: 0px 20px 51px rgba(0, 0, 0, 0.302);
        }
      }
      .page-action {
        top: 5949px;
        left: 323px;
        .action-item {
          width: 1055px;
          height: 353px;
          border-radius: 154px;
          &:hover {
          }
          span {
            font-size: 154px;
          }
          &.active {
            margin-right: 174px;
          }
        }
      }
    }
    .page-two {
      // height: 15498px;
      background: url("https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1701418292983.png");
      background-size: 100% auto;
      .channerSwiper {
        margin-top: 18.67vw;
        margin-bottom: 40.27vw;
      }
      .h1 {
        margin-top: 538px;
        font-size: 205px;
        font-family: Helvetica Black-Regular, Helvetica Black;
        font-weight: 400;
        line-height: 240px;
        text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
        background: linear-gradient(
          82deg,
          #522ec8 7%,
          #22a8b7 44%,
          #2f9be3 82%,
          #0075ff 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .h2 {
        .icon-planet {
          width: 451px;
          height: 451px;
        }
        span {
          font-size: 184px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 800;
          color: #333333;
          line-height: 143px;
          letter-spacing: 92px;
        }
      }
      .intro {
        flex-direction: column;
        width: 2949px;
        margin-bottom: 0;
        .intro-item {
          width: 2949px;
          height: 1485px;
          margin: 0;
          margin-bottom: 400px;
          padding-left: 350px;
          padding-top: 280px;
          transform: scale(1.1);
          &:nth-child(1),
          &:nth-child(3) {
            margin-right: 0;
          }
          &:hover {
          }
          span {
            &:first-child {
              font-size: 143px;
            }
            &:nth-child(2) {
              font-size: 92px;
            }
          }
          p {
            font-size: 113px;
            width: 2433px;
            line-height: 195px;
          }
          &.bg-purple {
            span {
              &:nth-child(2) {
              }
            }
          }
          &.bg-green {
            span {
              &:nth-child(2) {
              }
            }
          }
          &.bg-orange {
            span {
              &:nth-child(2) {
              }
            }
          }
          &.bg-blue {
            span {
              &:nth-child(2) {
              }
            }
          }
        }
      }
      .list {
        margin-top: 732px;
        .list-item {
          width: 2949px;
          height: 3041px;
          border-radius: 102px;
          transform: scale(1.05);
          &:hover {
          }
          &:nth-child(1),
          &:nth-child(2) {
            margin: 0;
          }
          img {
            width: 100%;
            height: 1746px;
          }
          .down {
            width: 100%;
            height: 1295px;
            box-sizing: border-box;
            padding: 159px 205px;
            .label {
              font-size: 123px;
              font-family: Helvetica Black-Regular, Helvetica Black;
              font-weight: 400;
              line-height: 144px;
              text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
              background: linear-gradient(
                270deg,
                #4e54ff 13%,
                #21b4c5 51%,
                #1285d1 91%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .title {
              font-size: 154px;
              margin-top: 140px;
            }
            .time {
              margin-top: 108px;
              .icon-time {
                width: 160px;
                height: 160px;
              }
              span {
                font-size: 123px;
                &:nth-child(2) {
                }
              }
            }
          }
        }
      }
      .list-arrow {
        margin-top: 302px;
        display: flex;
        justify-content: center;
        column-gap: 12vw;
        margin-bottom: 17.5vw;
        .arrow-left {
          width: 253px;
          height: 253px;
          margin: 0;
        }
        .arrow-right {
          width: 253px;
          height: 253px;
        }
      }
    }
    .page-three {
      height: 6134px;
      background: url(#{$PublicEnv}/index/bg_3_mobile.png);
      background-size: 100% 100%;
      .left {
        top: 4352px;
        left: 451px;
        .h1 {
          display: none;
        }
        .h2,
        .h3,
        .h4 {
          margin-bottom: 102px;
          .icon-phone {
            width: 102px;
            height: 102px;
          }
          .icon-company {
            width: 102px;
            height: 102px;
          }
          .icon-location {
            width: 102px;
            height: 102px;
          }
          span {
            margin-left: 87px;
            font-size: 123px;
          }
        }
      }
      .right {
        top: 645px;
        right: 599px;
        .h1 {
          font-size: 246px;
          font-family: Helvetica Black-Regular, Helvetica Black;
          font-weight: 400;
          line-height: 288px;
          text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
          background: linear-gradient(
            82deg,
            #7b58ee 4%,
            #21b4c5 37%,
            #1285d1 81%,
            #106edc 100%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .h2 {
          margin-top: 97px;
          font-size: 143px;
        }
        .qrcode {
          margin-top: 251px;
          width: 1044px;
          height: 1044px;
        }
      }
      .bottom {
        bottom: 404px;
        padding: 0 250px;
        justify-content: space-evenly;
        span {
          font-size: 92px;
          margin-right: 0;
          opacity: 0.5;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}
</style>
